import React from 'react';

import {ReactComponent as TutorialLinux} from "./app-img/Tutorial_linux.svg"
import {ReactComponent as TutorialWindows} from "./app-img/Tutorial_windows.svg"
import {ReactComponent as TutorialSchool} from "./app-img/Tutorial_school.svg"
import {ReactComponent as TutorialSpo} from "./app-img/Tutorial_spo.svg"
import {ReactComponent as TutorialDpo} from "./app-img/Tutorial_dpo.svg"
import {ReactComponent as TutorialVo} from "./app-img/Tutorial_vo.svg"
import {ReactComponent as Divider} from "./app-img/Divider.svg"


import {ReactComponent as InstrOne} from "./app-img/dostup_reg.svg"
import {ReactComponent as InstrTwo} from "./app-img/lk_org.svg"
import {ReactComponent as InstrTree} from "./app-img/vvod_rukami.svg"
import {ReactComponent as InstrFour} from "./app-img/import.svg"
import {ReactComponent as InstrFive} from "./app-img/5.svg"
import {ReactComponent as InstrFiveOne} from "./app-img/5_1.svg"
import {ReactComponent as InstrSix} from "./app-img/reestr.svg"
import {ReactComponent as InstrSeven} from "./app-img/statistika.svg"
import {ReactComponent as InstrEt} from "./app-img/import_exel.svg"
import {ReactComponent as InstrEtOne} from "./app-img/nastroyka_blankov_i_pechat.svg"
import {ReactComponent as InstrEtTwo} from "./app-img/import_spravok.svg"
import {ReactComponent as InstrNn} from "./app-img/export_exel.svg"
import {ReactComponent as InstrTn} from "./app-img/export.svg"

import { Link } from 'react-router-dom';
import NewsCont from 'NewsCont';

const AnonPageOrg = () => {
    return (
<div className="App-main-anon">
  <div id="Content-container-org">
    <h1>Важная информация</h1>
    <div id="Important-org">
      {/*<Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_+_доступ_Школы.docx" target="_blank" rel="noreferrer"><TutorialSchool></TutorialSchool></Link>
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_+_доступ_СПО.docx" target="_blank" rel="noreferrer"><TutorialSpo></TutorialSpo></Link>
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_ДПО_и_ПО.docx" target="_blank" rel="noreferrer"><TutorialDpo></TutorialDpo></Link>
      <Link id="Org-link" to="/public/orgs/1_Инструкция_регистрация_+_доступ_ВО.docx" target="_blank" rel="noreferrer"><TutorialVo></TutorialVo></Link>*/}

<Link id="Org-link" to="/public/orgs/1_Регистрация_организаций.pdf" target="_blank" rel="noreferrer"><InstrOne></InstrOne></Link>
<Link id="Org-link" to="/public/orgs/2_Инструкция_Настройка_личного_кабинета.pdf" target="_blank" rel="noreferrer"><InstrTwo></InstrTwo></Link>
<Link id="Org-link" to="/public/orgs/3_Инструкция_Создание_форм_и_ручной_ввод_документов.pdf" target="_blank" rel="noreferrer"><InstrTree></InstrTree></Link>
<Link id="Org-link" to="/public/orgs/4_Инструкция_импорт_групповой_ввод.pdf" target="_blank" rel="noreferrer"><InstrFour></InstrFour></Link>
<Link id="Org-link" to="/public/orgs/5_Инструкция_подтверждение_права_подписи_РУКОВОДИТЕЛЕМ_и_подпись.pdf" target="_blank" rel="noreferrer"><InstrFive></InstrFive></Link>
<Link id="Org-link" to="/public/orgs/5_1_Инструкция_как_убрать_подтверждение_сертификата_УКЭП.pdf" target="_blank" rel="noreferrer"><InstrFiveOne></InstrFiveOne></Link>
<Link id="Org-link" to="/public/orgs/6_Инструкция_Работа_в_реестре.pdf" target="_blank" rel="noreferrer"><InstrSix></InstrSix></Link>
<Link id="Org-link" to="/public/orgs/7_Инструкция_Раздел_Статистика.pdf" target="_blank" rel="noreferrer"><InstrSeven></InstrSeven></Link>
<Link id="Org-link" to="/public/orgs/8_1_Инструкция_настройка_бланков_печать_сертификата.pdf" target="_blank" rel="noreferrer"><InstrEtOne></InstrEtOne></Link>
<Link id="Org-link" to="/public/orgs/8_Сертификаты_актуальный_импорт.xlsx" target="_blank" rel="noreferrer"><InstrEt></InstrEt></Link>
<Link id="Org-link" to="/public/orgs/8_2_Справки_актуальный_импорт.xlsx" target="_blank" rel="noreferrer"><InstrEtTwo></InstrEtTwo></Link>
<Link id="Org-link" to="/public/orgs/9_экспорт_актуальный.xlsx" target="_blank" rel="noreferrer"><InstrNn></InstrNn></Link>
<Link id="Org-link" to="/public/orgs/10_Описание_столбцов_экспорта.pdf" target="_blank" rel="noreferrer"><InstrTn></InstrTn></Link>

      <Divider id="Org-divider"></Divider>
      <Link id="Org-link" to="/public/Tutorial_Linux.docx" download="Tutorial_Linux" target="_blank" rel="noreferrer"><TutorialLinux></TutorialLinux></Link>
      <Link id="Org-link" to="/public/Tutorial_Windows.docx" download="Tutorial_Windows" target="_blank" rel="noreferrer"><TutorialWindows></TutorialWindows></Link>
    </div>
    <h1>Новости ЦДО</h1>
    <NewsCont />
  </div>
</div>)
};

export default AnonPageOrg;