import React from 'react';

import img1 from "./app-img/anon-img-first.png";
import img2 from "./app-img/anon-img-second.png";
import img3 from "./app-img/anon-img-third.png";

//import img1 from "./app-img/anon-img-first.jpeg";
//import img2 from "./app-img/anon-img-second.jpg";
//import img3 from "./app-img/anon-img-third.jpg";

import { Link } from "react-router-dom";

import {ReactComponent as ImgLock} from "./app-img/lock.svg"
import {ReactComponent as ImgBook} from "./app-img/book.svg"
import {ReactComponent as ImgCloud} from "./app-img/cloud.svg"

import {OwnerLoginButton} from "registration/registrationForm";

const AnonPageMain = () => {
  let ercdo_lnk='https://ercdo.obrnadzor.gov.ru'
  if ( window.location.hostname === '10.20.16.11'
          || window.location.hostname.startsWith('172.16.87.')
          || window.location.hostname === 'localhost'
          ) ercdo_lnk='http://10.20.16.11'
    return (
  <div className="App-main-anon">
  <div id="Content-container">
    <div id="About-text">
      <img src={img1} alt="Image 1"  />
      <div className="hd1">Что такое Единый реестр цифровых <br />документов об образовании?</div><br />
      <div className="hd2">И для чего он нужен?</div><br />
      <span>Единый реестр цифровых документов об образовании 
      является частью процесса цифровизации образовательной сферы. 
      Он нужен для упрощения процедуры подтверждения уровня образования, 
      минимизации вероятности внесения некорректных сведений документов 
      и надежного хранения данных в цифровом виде.</span>      
    </div>

    <div id="Content-graduate">
      <img src={img2} alt="Image 2" className="imageMain2" />
      <h1>ЕР ЦДО для выпускников</h1>
      <span>Вы в любой момент можете получить доступ к вашим документам 
        об окончании учебных заведений. Теперь не нужно бояться потерять 
        свой диплом, при этом носить бумажный оригинал куда-либо 
        нет необходимости. </span>
      {/*<OwnerLoginButton>Личный кабинет обладателя ЦДО</OwnerLoginButton>*/}
    </div>
    <div id="Content-org">
      <h1>ЕР ЦДО для образовательных организаций</h1>
      <span>Вы можете заполнять, проверять, подписывать и перевыпускать документы 
        об образовании с помощью данного модуля с любого компьютера, имеющего 
        доступ в интернет и Яндекс.Браузер. Простота и удобство интерфейса 
        сделают работу с документами легче и приятнее</span>
      <Link to={ercdo_lnk}><button className="Button-header">Личный кабинет организации</button></Link>
      <img src={img3} alt="Image 3" className="imageMain3" />
    </div>
    <div id="Content-advantages">
      <h1>Главные преимущества системы</h1>
      <div>
        <ImgLock className="imageIcons" />
      </div>
      <span>Высокая безопасность системы, обеспечиваемая использованием УКЭП и TLS соединения</span>
      <div>
        <ImgBook className="imageIcons" />
      </div>
      <span>Более простая и удобная работа с документами всех уровней образования</span>
      <div>
        <ImgCloud className="imageIcons" />
      </div>
      <span>Работать с документами можно с любого устройства, имеющего Яндекс.Браузер</span>
    </div>
  </div>
</div>)
};

export default AnonPageMain;
